import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import References from '../components/References.vue';
import Budget from '../views/Budget.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/budget',
    name: 'Budget',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Budget,
  },
  {
    path: '/references',
    name: 'References',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: References,
    props: { msg: 'Reference materials provided as part of the default project.' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
