<template>
  <div>
    <div class="row ">
      <div class="col alert-success text-center summarybar">
        <strong>Income</strong>

        {{
          income.toLocaleString('en-US', {
            font: 'courier',
            style: 'currency',
            currency: 'USD',
          })
        }}
      </div>
      <div class="col alert-info text-center summarybar">
        <strong>Savings</strong>
        {{
          savings.toLocaleString('en-US', {
            font: 'courier',
            style: 'currency',
            currency: 'USD',
          })
        }}
      </div>
      <div class="col alert-warning text-center summarybar">
        <strong>Expenses</strong>
        {{
          expenses.toLocaleString('en-US', {
            font: 'courier',
            style: 'currency',
            currency: 'USD',
          })
        }}
      </div>
    </div>
    <div class="row " :class="netStyle" >
      <div class="col text-center summarybar">
        <div class="dropdown " >
          <strong>Net {{ summaryType }} Income </strong>
          <a
            class="dropdown-toggle"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li><button class="dropdown-item" v-on:click="monthlySummary">Monthly</button></li>
            <li><button class="dropdown-item" v-on:click="quarterlySummary">Quarterly</button></li>
            <li><button class="dropdown-item" v-on:click="annualSummary">Annual</button></li>
          </ul>
          {{
            net.toLocaleString('en-US', {
              font: 'courier',
              style: 'currency',
              currency: 'USD',
            })
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BudgetSummary',
  mounted() {
    const summaryType = JSON.parse(localStorage.getItem('summaryType'));
    if (summaryType) {
      this.summaryType = summaryType;
    }
  },
  setup() {},
  props: {
    budget: Array,
  },
  data: () => ({
    summaryType: 'Annual',
  }),
  methods: {
    computeAnnualAmount(entry) {
      switch (entry.frequency) {
        case 'Monthly':
          return 12 * entry.amount;
        case 'Weekly':
          return 52 * entry.amount;
        case 'Bi-Weekly':
          return 26 * entry.amount;
        case 'Twice-Monthly':
          return 24 * entry.amount;
        case 'Quarterly':
          return 4 * entry.amount;
        case 'Semi-Annually':
          return 2 * entry.amount;
        case 'Annually':
          return entry.amount;
        default:
          return 0;
      }
    },
    monthlySummary() {
      this.summaryType = 'Monthly';
      this.writeToLocalStorage();
    },
    quarterlySummary() {
      this.summaryType = 'Quarterly';
      this.writeToLocalStorage();
    },
    annualSummary() {
      this.summaryType = 'Annual';
      this.writeToLocalStorage();
    },
    summaryDivisor() {
      switch (this.summaryType) {
        case 'Monthly':
          return 12;
        case 'Quarterly':
          return 4;
        case 'Annual':
        default:
          return 1;
      }
    },
    writeToLocalStorage() {
      localStorage.setItem('summaryType', JSON.stringify(this.summaryType));
    },
  },
  computed: {
    income() {
      const result = this.budget.reduce((sumin, entry) => {
        let sum = sumin;

        if (entry.type === 'Income') {
          sum += this.computeAnnualAmount(entry);
        }

        return sum;
      }, 0);
      return result / this.summaryDivisor();
    },
    savings() {
      const result = this.budget.reduce((sumin, entry) => {
        let sum = sumin;

        if (entry.type === 'Savings') {
          sum += this.computeAnnualAmount(entry);
        }
        return sum;
      }, 0);
      return result / this.summaryDivisor();
    },
    expenses() {
      const result = this.budget.reduce((sumin, entry) => {
        let sum = sumin;

        if (entry.type === 'Expense') {
          sum += this.computeAnnualAmount(entry);
        }
        return sum;
      }, 0);
      return result / this.summaryDivisor();
    },
    net() {
      return this.income - this.savings - this.expenses;
    },
    netStyle() {
      if (this.net >= 0) {
        return 'alert-success';
      }
      return 'alert-danger';
    },
  },
};
</script>
<style scoped>
.summarybar {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
