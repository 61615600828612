<template>
  <div class="budget container-fluid">
    <BudgetSummary :budget="data"></BudgetSummary>
    <div class="row">
      <div class="col form-group">
        <label class="form-label" for="newType"><strong>Type</strong></label>
        <select class="form-select" id="newType" v-model="newItem.type">
          <option>Income</option>
          <option>Savings</option>
          <option>Expense</option>
        </select>
      </div>
      <div class="col form-group">
        <label class="form-label" for="newFrequency"><strong>Frequency</strong></label>
        <select class="form-select" id="newFrequency" v-model="newItem.frequency">
          <option>Weekly</option>
          <option>Bi-Weekly</option>
          <option>Twice-Monthly</option>
          <option>Monthly</option>
          <option>Quarterly</option>
          <option>Semi-Annually</option>
          <option>Annually</option>
        </select>
      </div>
      <div class="col form-group">
        <label class="form-label" for="newDescription"><strong>Description</strong></label>
        <input
          id="newDescription"
          ref="newDescription"
          type="text"
          class="form-control"
          placeholder="Text Description"
          v-model="newItem.description"
          required="true"
        />
      </div>
      <div class="col form-group">
        <label class="form-label" for="newAmount"><strong>Amount</strong></label>
        <div class="input-group w-100">
          <input
            type="number"
            min="0.00"
            class="form-control"
            placeholder=""
            id="newAmount"
            v-model="newItem.amount"
          />

          <button type="button" id="addItemButton" class="btn btn-primary" v-on:click="addItem">
            Add
          </button>
        </div>
      </div>
    </div>
    <hr/>

    <table class="table" >
      <tbody>
        <tr v-for="entry in data" v-bind:key="entry.id" v-bind:class="isExpense(entry.type)">
          <!-- <th scope="row">{{ entry.type }}</th> -->
          <td>{{ entry.frequency }}</td>
          <td colspan="2">{{ entry.description }}</td>
          <td class="text-end">
            {{
              entry.amount.toLocaleString('en-US', {
                font: 'courier',
                style: 'currency',
                currency: 'USD',
              })
            }}
          </td>
          <td style="padding: 0">
            <ul class="nav nav-pills flex-column">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="true"
                ></a>
                <div
                  class="dropdown-menu"
                  style="
                    position: absolute;
                    inset: 0px auto auto 0px;
                    margin: 0px;
                    transform: translate(0px, 42px);
                  "
                  data-popper-placement="bottom-start"
                >
                  <button class="dropdown-item" v-on:click="deleteItem(entry.id)">Delete</button>
                </div>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BudgetSummary from '../components/BudgetSummary.vue';

export default {
  name: 'Budget',
  components: {
    BudgetSummary,
  },
  mounted() {
    console.log('mounted');
    this.typeMap.set('Income', 3);
    this.typeMap.set('Savings', 2);
    this.typeMap.set('Expense', 1);
    const data = JSON.parse(localStorage.getItem('budget'));

    if (data && data.length > 0) {
      this.data = data;
    }
    this.nextId = data.reduce((resultin, item) => {
      let result = resultin;
      if (item.id >= result) {
        result = item.id + 1;
      }
      return result;
    });
  },
  data: () => ({
    typeMap: new Map(),
    newItem: {
      type: 'Expense',
      frequency: 'Monthly',
      description: '',
      amount: 0.0,
    },
    nextId: 0,
    data: [],
  }),
  methods: {
    isExpense(type) {
      switch (type) {
        case 'Income':
          return 'table-success';
        case 'Expense':
          return 'table-warning';
        default:
          return 'table-info';
      }
    },
    addItem() {
      console.log(this.newItem.description);
      if (this.newItem.description.length === 0) {
        return;
      }
      if (this.newItem.amount <= 0) {
        this.newItem.amount = 0;
        return;
      }
      const newItem = {
        id: this.nextId,
        type: this.newItem.type,
        frequency: this.newItem.frequency,
        description: this.newItem.description,
        amount: parseFloat(this.newItem.amount),
      };

      console.log(newItem);
      this.nextId += 1;
      if (this.data.length > 0) {
        this.data.splice(this.arrayLocation(newItem) + 1, 0, newItem);
      } else {
        this.data.push(newItem);
      }
      this.writeToLocalStorage();
      this.newItem.description = '';
      this.$refs.newDescription.focus();
    },
    deleteItem(itemId) {
      console.log(itemId);
      console.log('delete clicked for item ');
      this.data = this.data.filter((item) => item.id !== itemId);
      this.writeToLocalStorage();
    },
    arrayLocation(newItem, startin, endin) {
      const start = startin || 0;
      const end = endin || this.data.length;
      const pivot = parseInt(start + (end - start) / 2, 10);
      if (this.data[pivot] === newItem) return pivot;
      if (end - start <= 1) {
        return !this.dataLessThan(newItem, this.data[pivot]) ? pivot - 1 : pivot;
      }
      if (!this.dataLessThan(this.data[pivot], newItem)) {
        return this.arrayLocation(newItem, pivot, end);
      }
      return this.arrayLocation(newItem, start, pivot);
    },
    dataLessThan(item1, item2) {
      if (this.typeMap.get(item1.type) < this.typeMap.get(item2.type)) return true;
      if (this.typeMap.get(item1.type) > this.typeMap.get(item2.type)) return false;
      if (item1.amount < item2.amount) return true;
      return false;
    },
    writeToLocalStorage() {
      localStorage.setItem('budget', JSON.stringify(this.data));
    },
  },
};
</script>
