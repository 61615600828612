<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="./assets/logo.png" />
        arcarl.com
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarColor01"
        aria-controls="navbarColor01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarColor01">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/budget">Budget</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/references">References</router-link>
          </li>
        </ul>
        <form class="d-flex">
          <input class="form-control me-sm-2" type="text" placeholder="Search" />
          <button v-on:click="loginButtonClick" class="btn btn-secondary my-2 my-sm-0">
            Login
          </button>
        </form>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<script>
export default {
  setup() {},
  methods: {
    loginButtonClick: () => {
      alert('Login not implemented.');
    },
  },
};
</script>

<style>
</style>
